<template>
  <div>
    <company-nav></company-nav>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-one-quarter">
            <b-menu class="box" activable>
              <b-menu-list>
                <b-menu-item
                  v-for="(menuDataElem, index) in menuData"
                  :key="`menu-${index}`"
                  :label="menuDataElem.label"
                  :active.sync="menuDataElem.isActive"
                  :icon="menuDataElem.icon"
                  :disabled="menuDataElem.isDisabled"
                  @click="handleClick(index)"
                ></b-menu-item>
              </b-menu-list>
            </b-menu>
          </div>
          <div class="column is-three-quarters">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompanyNav from "@/components/CompanyNav";

export default {
  components: {
    "company-nav": CompanyNav,
  },

  created() {
    this.$store.dispatch("companyOrders/getAllEvents");
  },

  data() {
    return {
      menuData: [
        {
          label: "Dane firmy",
          routeName: "CompanyProfile",
          isActive: false,
          isDisabled: false,
        },
        {
          label: "Kalendarz",
          routeName: "CompanyOrders",
          isActive: true,
          isDisabled: false,
        },
        {
          label: "Katalog usług",
          routeName: "CompanyServices",
          isActive: false,
          isDisabled: false,
        },
                {
          label: "Wszystkie zamówienia",
          routeName: "CompanyOrdersList",
          isActive: false,
          isDisabled: true,
        },
        {
          label: "Statystyki",
          routeName: "",
          isActive: false,
          isDisabled: true,
        },
        {
          label: "Rozliczenia",
          routeName: "",
          isActive: false,
          isDisabled: true,
        },
        {
          label: "Baza klientów",
          routeName: "",
          isActive: false,
          isDisabled: true,
        },
        {
          label: "Bramka SMS",
          routeName: "",
          isActive: false,
          isDisabled: true,
        },
        {
          label: "Mailing",
          routeName: "",
          isActive: false,
          isDisabled: true,
        },
        {
          label: "Zaproś nową osobę",
          routeName: "",
          isActive: false,
          isDisabled: true,
        },
        {
          label: "Wprowadź nowego klienta",
          routeName: "",
          isActive: false,
          icon: "plus",
          isDisabled: true,
        },
                {
          label: "Wyloguj",
          routeName: "Home",
          isActive: false,
          icon: "",
          isDisabled: false,
        },
      ],
    };
  },

  methods: {
    handleClick(index) {
      this.menuData.forEach((elem) => {
        elem.isActive = false;
      });

      this.menuData[index].isActive = true;

      if (!this.menuData[index].routeName) {
        return;
      }

      this.$router.push({ name: this.menuData[index].routeName });
    },
  },
};
</script>