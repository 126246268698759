<template>
  <section>
    <div class="navbar-background" >
      <b-navbar style="background-color: rgba(0, 0, 0, 0)" transparent>
        <template #brand>
          <a @click="$router.push({name: 'CompanyOrders'})">
          <div  >
            <b-image :src="require('@/assets/img/home/logo.png')"></b-image>
          </div>
          </a>
        </template>

        <template #end>
          <b-navbar-dropdown right>
            <template #label>
              <div class="has-text-white">
                {{ name }}
              </div>
            </template>
            <b-navbar-item @click="logout">
              <div class="media">
                <b-icon
                  class="media-left"
                  icon="logout"
                  type="is-danger"
                ></b-icon>
                <div class="media-content">Wyloguj</div>
              </div>
            </b-navbar-item>
          </b-navbar-dropdown>
        </template>
      </b-navbar>
      <div style="height: 10vh">
<!--         <nav class="is-flex is-justify-content-space-evenly has-text-white">
          <div>AKTUALNOŚCI</div>
          <div>HURTOWNIA</div>
          <div>FORUM</div>
          <div>SERWIS OGŁOSZENIOWY</div>
          <div>STREFA KLIENTA</div>
          <div>KONTAKT</div>
        </nav>
        <hr /> -->
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    logout() {
      //console.log("Wylogowuje");
      this.$router.push({ name: "Home" });
    },
  },

  computed: {
    name() {
      return this.$store.getters["companyProfile/name"];
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar-background {
  background-image: url("../assets/img/banners/banner6.png");
  background-size: cover;
}
</style>